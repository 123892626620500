import React from 'react';
import { Paper, Chip, Typography, Button } from '@material-ui/core';
import { Video, Book } from 'react-feather';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import Layout, { Wrapper } from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { Branding } from '../components/Branding';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { Subscription } from '../components/Subscribe';
import { Highlight } from '../components/Highlight';
import { Blockquote } from '../components/Blockquote';
import { AuthorName, AuthorImage } from '../components/Author';
import { ENABLE_SIGNUP } from '../constants';

const Pro = styled('div')`
  background-color: ${(p) => p.theme.palette.grey['800']};
  color: #1890ff;
  display: inline-block;
  font-size: 10px;
  margin-left: 6px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: 2px 6px;
`;

const ResourceGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 36px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const AuthorWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 18px;

  p {
    margin: 0 12px 0 0;
  }
`;
const JobTitle = styled('span')`
  display: inline-block;
  text-transform: uppercase;
  opacity: 0.6;
  font-size: 0.8rem;
  font-weight: 700;
  margin-left: 12px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Member = styled('div')`
  display: inline-block;
  position: relative;
  margin-right: 24px;
`;

const MemberImg = styled('img')`
  border: 3px solid white;
  border-radius: 100%;
  display: inline-block;
`;

const ListHeading = styled(Typography)`
  color: ${(p) => p.theme.palette.primary.main};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 2rem;
`;

const Img = styled('img')`
  max-width: 80%;
  border-radius: 8px;
  margin: 0 auto;
  display: block;
  border: 1px solid ${(p) => p.theme.palette.grey['900']};
  box-shadow: 0 5px 20px 0 hsl(0deg 0% 0%);
`;

const PreviewImg = styled('img')`
  max-width: 100%;
  border-radius: 8px;
  margin: 0 auto;
  display: block;
  border: 1px solid ${(p) => p.theme.palette.grey['900']};
  box-shadow: 0 5px 20px 0 hsl(0deg 0% 0%);
`;

const Preview = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  max-width: 800px;
  margin: 12px auto;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  div {
    padding: 12px;
  }
`;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    border-bottom: 2px solid ${theme.palette.primary.main};
    color: ${theme.palette.primary.main};
  `};
`;

const StyledA = styled('a')`
  border-bottom: 2px solid ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Join the Blogging for Devs Community"
        siteUrl="https://bloggingfordevs.com"
        description="Grow with a community of over 100 developers building their blogs and audience through writing online."
        image="/images/bfd-community-preview.jpg"
        socialSharingImage="/images/bfd-community-preview.jpg"
        pathname="community/"
      />
      <ProductHuntBanner />
      <Wrapper style={{ maxWidth: '650px' }}>
        <Branding
          to="/"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          Blogging for Devs <Pro>PRO</Pro>
        </Branding>
        <div style={{ textAlign: 'center', marginBottom: '100px' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Join Our Community
          </Typography>
        </div>
        <Typography variant="h5" component="h2" paragraph>
          <strong>Why we're here</strong>
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          When I started blogging actively in 2016, the thing that changed the
          game for me and really helped me grow wasn't a course or a tool.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          It was community.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          Communities helped me learn to build successful blogs, finally
          understand SEO, and connect with experts who could give me a leg up.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          I've got no doubt that I would be years behind (or even given up)
          reaching my one million reader milestone without them.
        </Typography>
        <Typography
          variant="h6"
          component="p"
          paragraph
          style={{ lineHeight: '36px' }}
        >
          <Highlight>
            That's why I wanted to create a similar space{' '}
            <em>specifically for developers</em>.
          </Highlight>
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          The mission of the Blogging for Devs Community is to be a supportive
          place for developers like you to give and receive advice on how to
          write online, grow your audience, improve your career opportunities,
          and attract people to your projects.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          And most importantly, to do this in a way that's authentic and
          genuinely helpful to others.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          Blogging and growing an audience is <em>so much harder</em> in
          isolation.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          That's why we're here.
        </Typography>
        <br />
      </Wrapper>
      <Img src="/images/bfd-community.jpg" />
      <Wrapper>
        <br />
        <br />
        <br />
        <br />
        <Typography variant="h5" component="h2" paragraph>
          <strong>Who are our members?</strong>
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          Our members range from beginner to experts across a ton of dimensions.
          They are prolific bloggers, conference speakers, founders, and
          creators of all stripes.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          We also have pro marketers who're learning to code, and bloggers who
          want to learn more about writing for a technical audience.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          <strong>
            You don't even have to write technical content to fit in.
          </strong>
        </Typography>
        <Typography
          variant="h6"
          component="p"
          paragraph
          style={{ lineHeight: '36px' }}
        >
          <Highlight>
            No matter if you're a code newbie or an industry veteran, if you
            want to become a better blogger and weave writing into your career,
            this community is for you.
          </Highlight>
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          What's important is that you're an action-taker and motivated to grow!
        </Typography>
        <br />
        <br />
        <div
          style={{
            margin: '0 auto',
            display: 'inline-block',
            textAlign: 'center',
            display: 'none'
          }}
        >
          <Member style={{ top: '-13px' }}>
            <Chip
              color="primary"
              style={{
                backgroundColor: '#1890ff',
                position: 'absolute',
                bottom: '-13px',
                zIndex: 1
              }}
              label="Creators"
            />
            <MemberImg
              src="/images/swyx.jpg"
              alt="Swyx"
              style={{ width: '100px' }}
            />
          </Member>
          <Member>
            <Chip
              color="primary"
              style={{
                backgroundColor: '#1890ff',
                position: 'absolute',
                top: '-10px',
                left: '-40px',
                zIndex: 1
              }}
              label="Tech Speakers"
            />
            <MemberImg
              src="/images/sara-small.jpg"
              alt="Sara Vieira"
              style={{ width: '150px' }}
            />
          </Member>
          <Member>
            <Chip
              color="primary"
              style={{
                backgroundColor: '#1890ff',
                position: 'absolute',
                left: '-10px',
                top: '-22px',
                zIndex: 1
              }}
              label="Founders"
            />
            <MemberImg
              src="/images/fazle-small.jpg"
              alt="Fazle"
              style={{ width: '100px' }}
            />
          </Member>
          <Member>
            <Chip
              color="primary"
              style={{
                backgroundColor: '#1890ff',
                position: 'absolute',
                left: '-22px',
                top: '-12px',
                zIndex: 1
              }}
              label="YouTubers"
            />
            <MemberImg
              src="/images/gift-small.jpg"
              alt="Gift Egwuenu"
              style={{ width: '150px' }}
            />
          </Member>
          <Member>
            <Chip
              color="primary"
              style={{
                backgroundColor: '#1890ff',
                position: 'absolute',
                left: '-50px',
                bottom: '12px',
                zIndex: 1
              }}
              label="Freelancers"
            />
            <MemberImg
              src="/images/rey-small.jpg"
              alt="Rey Van Den Berg"
              style={{ width: '100px' }}
            />
          </Member>
          <Member>
            <Chip
              color="primary"
              style={{
                backgroundColor: '#1890ff',
                position: 'absolute',
                top: '10px',
                left: '-50px',
                zIndex: 1
              }}
              label="Tech Educators"
            />
            <MemberImg
              src="/images/hiro-small.jpg"
              alt="Sara Vieira"
              style={{ width: '150px' }}
            />
          </Member>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Typography variant="h5" component="h2" paragraph>
          <strong>Components of Our Community</strong>
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          Our community has about 100 members and grows every week.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          It's active, but not overwhelming. There are new posts every day. We
          use a platform called{' '}
          <StyledA href="http://circle.so/" target="_blank">
            Circle
          </StyledA>{' '}
          for discussions.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          Here's a peak of what you'll find inside.
        </Typography>
      </Wrapper>
      <Preview>
        <div>
          <ListHeading variant="h6" component="h3">
            Discussion
          </ListHeading>
          <Typography variant="h6" component="p" paragraph>
            Most of the community discussions happens in a couple spaces, so you
            don't need to hunt everywhere for the topic you saw last week.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            This is the place to get to know each other, give and get feedback
            on blog posts and projects, RSVP for upcoming events, ask questions,
            and discuss strategy.
          </Typography>
        </div>
        <div>
          <PreviewImg src="/images/community-discussions.jpg" />
        </div>
      </Preview>
      <Preview>
        <div>
          <ListHeading variant="h6" component="h3">
            Virtual Events + AMAs
          </ListHeading>
          <Typography variant="h6" component="p" paragraph>
            We have monthly meetups you can join to connect with fellow dev
            bloggers and get feedback or ideas on how to grow your blog.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Going forward, we'll add more virtual events and workshops you can
            join as a Blogging for Devs community member.
          </Typography>
        </div>
        <div>
          <PreviewImg src="/images/community-events.jpg" />
        </div>
      </Preview>
      <Preview>
        <div>
          <ListHeading variant="h6" component="h3">
            Groups
          </ListHeading>
          <Typography variant="h6" component="p" paragraph>
            Dive deeper into specific goals and strategies behind growing your
            blog in our community groups. Members can also create their own
            group. For instance, a few of our members recently created an
            accountability group.
          </Typography>
        </div>
        <div>
          <PreviewImg src="/images/community-groups.jpg" />
        </div>
      </Preview>
      <Wrapper>
        <Typography variant="h5" component="h2" paragraph>
          <strong>Private Community Resources</strong>
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          In addition to access to a private, supportive community of developer
          bloggers, you'll also get access to guides, curated resources, and
          screencasts.
        </Typography>
        <br />
        <br />
        <ResourceGrid>
          <Paper style={{ padding: '36px 36px' }}>
            <Typography variant="h6" component="p" paragraph>
              <Book size={18} /> &nbsp;<strong>Resource Library</strong>
              <br />
              <br />
              Use the resource library to spend less time evaluating tools and
              more time creating impactful content and growing your blog's
              audience.
            </Typography>
          </Paper>
          <Paper style={{ padding: '36px 36px' }}>
            <Typography variant="h6" component="p" paragraph>
              <Video size={18} /> &nbsp;
              <strong>Case Study Video Series</strong>
              <br />
              <br />
              See how to grow a brand new website with a content strategy,
              analyzing competitors, choosing keywords, writing and SEO.
            </Typography>
          </Paper>
        </ResourceGrid>
        <br />
        <br />
        <br />
        <br />
        <Typography variant="h5" component="h2" paragraph>
          <strong>Code of Conduct</strong>
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          The Blogging for Devs Community is committed to providing a safe,
          supportive, and welcoming space for all, regardless of gender, sexual
          orientation, ability, ethnicity, socioeconomic status, and religion
          (or lack thereof). We refer to the{' '}
          <StyledA href="https://berlincodeofconduct.org/" target="_blank">
            Berlin Code of Conduct
          </StyledA>
          .
        </Typography>
        <br />
        <br />
        <Paper style={{ padding: '24px 12px' }}>
          <Typography
            variant="h5"
            component="h2"
            id="signup"
            paragraph
            style={{
              textAlign: 'center',
              margin: '36px auto',
              fontWeight: 700,
              maxWidth: '500px'
            }}
          >
            Become part of the
            <br />
            Blogging for Devs Community ✍️
          </Typography>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{
              textAlign: 'center',
              maxWidth: '500px',
              margin: '24px auto'
            }}
          >
            We'd love to welcome you into the community. Secure your founding
            member discount by joining the waitlist now.
          </Typography>
          <div style={{ textAlign: 'center', marginBottom: '24px' }}>
            <Button
              href="https://airtable.com/shrhA0MVsBnOyOA5b"
              target="_blank"
              size="large"
              color="primary"
              variant="contained"
            >
              Join the Waitlist
            </Button>
          </div>
        </Paper>
        {ENABLE_SIGNUP && <Subscription />}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {ENABLE_SIGNUP && (
          <>
            <Typography variant="h5" component="h2" paragraph>
              <strong>FAQs</strong>
            </Typography>
            <br />
            <Typography variant="h6" component="p" paragraph>
              <strong>
                What if I'm a developer but I don't blog about tech, is this
                Community right for me?
              </strong>
            </Typography>
            <Typography variant="h6" component="p" paragraph>
              Absolutely. We've got developers blogging about everything from
              travel, to interior design, to knitting. If you're technical and
              you want to build a blog and grow an audience, you'll fit right
              in.
            </Typography>
            <br />
            <br />
            <Typography variant="h6" component="p" paragraph>
              <strong>What about if my goal is to make money blogging?</strong>
            </Typography>
            <Typography variant="h6" component="p" paragraph>
              Definitely! Helping people monetize their blogs and make money
              from their content is one of my favorite things. We also have a
              dedicated Group in the Community for{' '}
              <span style={{ whiteSpace: 'nowrap', fontWeight: 700 }}>
                💸 Monetization
              </span>
              .
            </Typography>
            <br />
            <br />
            <Typography variant="h6" component="p" paragraph>
              <strong>
                What if I don't have a blog yet, should I join now?
              </strong>
            </Typography>
            <Typography variant="h6" component="p" paragraph>
              You can certainly join even if your blog isn't live yet. That
              said, I highly recommend getting over that hurtle ASAP so we can
              help you focus on growing the blog and writing great articles.
            </Typography>
            <br />
            <br />
            <Typography variant="h6" component="p" paragraph>
              <strong>Do you offer refunds?</strong>
            </Typography>
            <Typography variant="h6" component="p" paragraph>
              Sure. If you decide in the first 14 days of your membership that
              Blogging for Devs isn't a fit for you, you can request a refund,
              no questions asked.
            </Typography>
            <div style={{ margin: '56px auto 0', textAlign: 'center' }}>
              <Button
                href="#signup"
                size="large"
                color="primary"
                variant="contained"
              >
                Join the Community
              </Button>
            </div>
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
